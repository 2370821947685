@import url('https://indestructibletype.com/fonts/Jost.css');
:root {
  --blue: rgba(62,0,255,1);
  --yellow: rgba(255,165,0,1);
  --red: rgba(227,47,99,1);
  --green: #2a8104;
  --black: rgba(0,0,0,1);
  --white: rgba(255,255,255,1);
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: 'Jost', sans-serif;
}

/* FIXES */
.react-datepicker-popper {
    z-index: 2 !important;
}
