.the-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: inherit;
}
.the-logo h1 {
  font-feature-settings: "ss01";
  font-size: 2em;
  font-weight: 600;
  padding-bottom: 1em;
  margin: 0;
}
.the-logo svg {
  height: 1.3em;
  width: auto;
  margin-right: .4em;
  vertical-align: middle;
  overflow: visible !important;
  display: inline-block;
}
.the-logo svg path {
  fill: var(--blue);
}
