.theFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 0 .5em;
}
.theFooter p {
  text-align: center;
}
