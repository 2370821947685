@import url(https://indestructibletype.com/fonts/Jost.css);
:root {
  --blue: rgba(62,0,255,1);
  --yellow: rgba(255,165,0,1);
  --red: rgba(227,47,99,1);
  --green: #2a8104;
  --black: rgba(0,0,0,1);
  --white: rgba(255,255,255,1);
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: 'Jost', sans-serif;
}

/* FIXES */
.react-datepicker-popper {
    z-index: 2 !important;
}

.theFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 0 .5em;
}
.theFooter p {
  text-align: center;
}

.the-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: inherit;
}
.the-logo h1 {
  font-feature-settings: "ss01";
  font-size: 2em;
  font-weight: 600;
  padding-bottom: 1em;
  margin: 0;
}
.the-logo svg {
  height: 1.3em;
  width: auto;
  margin-right: .4em;
  vertical-align: middle;
  overflow: visible !important;
  display: inline-block;
}
.the-logo svg path {
  fill: var(--blue);
}

